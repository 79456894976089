import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-048942c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upload-pictures-container clearfix" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(['add-btn', 'justify-center', 'align-center', $props.mode])
    }, [
      _createVNode(_component_ion_icon, { icon: $setup.add }, null, 8, ["icon"]),
      _createElementVNode("input", {
        class: "uplodad-ipt",
        ref: "uploadInput",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.changeFile && $setup.changeFile(...args))),
        type: "file",
        accept: "image/*"
      }, null, 544)
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.images, (img, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['image-item', $props.mode]),
        key: index
      }, [
        _createElementVNode("img", {
          src: img.base64,
          alt: "",
          srcset: ""
        }, null, 8, _hoisted_2),
        _createElementVNode("div", {
          class: "close-icon",
          onClick: ($event: any) => ($setup.removeFile(index))
        }, [
          _createVNode(_component_ion_icon, { icon: $setup.close }, null, 8, ["icon"])
        ], 8, _hoisted_3)
      ], 2))
    }), 128))
  ]))
}