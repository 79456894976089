
import { ref, reactive, watch } from "vue";
import { IonIcon } from "@ionic/vue";
import { add, close } from "ionicons/icons";
import { imageRegular } from "@/utils/regular";
import { openTips } from "@/utils/alert";
import { uploadImage } from "@/service/common"

export default {
  name: "UploadPictures",
  props: {
    defaultImages: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 5,
    },
    mode: {
      type: String,
      default: "white", // black
    },
  },
  emits: ['success'],
  setup(props: any, context: any) {
    const images: any = reactive(props.defaultImages);
    const uploadInput: any = ref(null);
    const upload = async (filename: string, imgdata: string) => {
      const result = await uploadImage({
        filename,
        imgdata
      })
      images.push(result);
      context.emit('success', { ...images});
    }
    const canvasDataURL = (path: any, obj: any, callback: any) => {
      const img = new Image();
      img.src = path;
      img.onload = function () {
        let w = img.width;
        let h = img.height;
        const scale = w / h;
        w = obj.width || w;
        h = obj.height || w / scale;
        let quality = 0.7; // 默认图片质量为0.7
        const canvas = document.createElement("canvas");
        const ctx: any = canvas.getContext("2d");
        // 创建属性节点
        const anw: any = document.createAttribute("width");
        anw.nodeValue = w;
        const anh: any = document.createAttribute("height");
        anh.nodeValue = h;
        canvas.setAttributeNode(anw);
        canvas.setAttributeNode(anh);
        ctx.drawImage(this, 0, 0, w, h);
        // 图像质量
        if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
          quality = obj.quality;
        }
        // quality值越小，所绘制出的图像越模糊
        const base64 = canvas.toDataURL("image/jpeg", quality);
        callback(base64);
      };
    };
    const changeFile = () => {
      const {
        value: {
          files: [file],
        },
      } = uploadInput;
      if (imageRegular(file.type)) {
        openTips("文件类型必须是JPG、JPEG、PNG、GIF!");
        return;
      }
      if (images.length >= props.limit) {
        openTips(`图片已达到上限${props.limit}张!`);
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        canvasDataURL(
          reader.result,
          {
            quality: 0.5,
          },
          (base64Codes: string) => {
            upload(file.name, base64Codes)
            // images.push({
            //   name: file.name,
            //   base64: base64Codes
            // });
            // context.emit('success', { ...images});
          }
        );
      };
    };
    const removeFile = (index: number) => {
      images.splice(index, 1);
      context.emit('success', { ...images});
    };

    watch(() => props.defaultImages, (value: any) => {
      images.splice(0, images.length, ...value)
    });

    return { IonIcon, add, close, images, uploadInput, changeFile, removeFile };
  },
};
