
import {
  IonPage,
  IonHeader,
  IonContent,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonTextarea,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";
import Header from "@/components/header.vue";
import { skipRouter } from "@/router";
import { ref, reactive, onMounted } from "vue";
import {
  supplierDataCommit,
  getUserWorkerOrSupplierDetail,
  getDicSimpleList,
} from "@/service/member";
import { openTips, openModal } from "@/utils/alert";
import UploadPictures from "@/components/uploadPictures.vue";
import { userinfo, checklogin } from "@/utils/usermodule";
import { local } from "@/utils/storage.service";

export default {
  name: "SupplierInfo",
  components: {
    Header,
    IonHeader,
    IonContent,
    IonPage,
    UploadPictures,
    IonSelect,
    IonTextarea,
    IonSelectOption,
    IonInput,
  },
  setup() {
    let images: any = [];
    const pagedataobj: any = ref([]);
    const suppliertypelistobj: any = ref([]);
    const SupplierImgList: any = ref([]);
    const SupplierYyzhizhao: any = ref([]);

    const form = reactive({
      SupplierId: "",
      SupplierName: "",
      LinkMan: "",
      Mobile: "",
      SupplierType: "",
      ProductList: "",
      NianChanZhi: "",
      ThreeYearYeJi: "",
      Remark: "",
      SupplierImgList: "",
      SupplierYyzhizhao: "",
      SupplierImgListNew: [],
      SupplierYyzhizhaoNew: [],
    });

    const getUserWorkerOrSupplierDetailInfo = async () => {
      const loginuser = local.get("loginuser");
      console.log(loginuser);

      if (loginuser == null || loginuser.islogin != "1") {
        return;
      }

      const result = await getUserWorkerOrSupplierDetail({
        userid: loginuser.userid,
      });
      pagedataobj.value = result;

      form.SupplierId = pagedataobj.value.supplierId;
      form.SupplierName = pagedataobj.value.supplierName;
      form.LinkMan = pagedataobj.value.linkMan;
      form.Mobile = pagedataobj.value.mobile;
      form.SupplierType = String(pagedataobj.value.supplierType);
      form.ProductList = pagedataobj.value.productList;
      form.NianChanZhi = pagedataobj.value.nianChanZhi;
      form.ThreeYearYeJi = pagedataobj.value.threeYearYeJi;
      form.SupplierImgList = pagedataobj.value.supplierImgList;
      form.SupplierYyzhizhao = pagedataobj.value.supplierYyzhizhao;
      form.SupplierImgListNew = pagedataobj.value.supplierImgListNew;
      form.SupplierYyzhizhaoNew = pagedataobj.value.supplierYyzhizhaoNew;
      form.Remark = pagedataobj.value.remark;

      console.log(form.SupplierImgListNew);
    };

    const submitSupplier = async () => {
      console.log(form);
      if (form.SupplierName === "") {
        openTips("供应商名称不能为空!");
        return;
      }
      if (form.LinkMan === "") {
        openTips("联系人姓名不能为空!");
        return;
      }
      if (form.Mobile === "") {
        openTips("联系人电话不能为空!");
        return;
      }

      const result = await supplierDataCommit(form);
      if (result) {
        openModal("恭喜您，提交保存成功!");
        skipRouter("/tabs/member");
      }
    };

    const getTeamSettingDetailSupplierType = async () => {
      const result = await getDicSimpleList({
        enCode: "SupplierType",
      });
      suppliertypelistobj.value = result;
      //console.log(suppliertypelistobj.value);
    };

    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(() => {
      //检测是否登录
      checklogin();
      getTeamSettingDetailSupplierType();
      getUserWorkerOrSupplierDetailInfo();
    });

    const yyzzuploadSuccess = (info: any) => {
      images = info;
      form.SupplierYyzhizhao = "";
      const listimages = Object.values(images);
      listimages.forEach((item: any) => {
        if (form.SupplierYyzhizhao == "") {
          form.SupplierYyzhizhao = item["name"];
        } else {
          form.SupplierYyzhizhao = form.SupplierYyzhizhao + "," + item["name"];
        }
      });
      console.log(form.SupplierYyzhizhao);
    };

    const qyzzuploadSuccess = (info: any) => {
      images = info;
      form.SupplierImgList = "";
      const listimages = Object.values(images);
      listimages.forEach((item: any) => {
        if (form.SupplierImgList == "") {
          form.SupplierImgList = item["name"];
        } else {
          form.SupplierImgList = form.SupplierImgList + "," + item["name"];
        }
      });
      console.log(form.SupplierImgList);
    };

    return {
      skipRouter,
      form,
      submitSupplier,
      suppliertypelistobj,
      yyzzuploadSuccess,
      qyzzuploadSuccess,
    };
  },
};
